import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby'
import ReactWOW from 'react-wow';
import Lottie from 'react-lottie';

import LaunchData from '../../assets/lotties/seo.json'

const MainBanner = () => {

    const launchAnimation = {
        loop: true,
        autoplay: true,
        animationData: LaunchData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className="seo-banner-area">
            <div className="container">
                <div className="seo-banner-area-content">
                    <ReactWOW delay='.1s' animation='fadeInLeft'>
                        <h1>On améliore votre classement sur Google sous 90 jours ou on vous rembourse!</h1>
                    </ReactWOW>

                    <ReactWOW delay='.1s' animation='fadeInUp'>
                        {/* <img src={MainImage} alt="image" /> */}
                        <Lottie
                            options={launchAnimation}
                            height="80%"
                            width="80%"
                        />
                    </ReactWOW>
                    
                    <ReactWOW delay='.1s' animation='fadeInLeft'>
                        <h3>Vous en avez assez d'apparaître sur la troisième page de résultats et de passer à côté de centaines de clients qui surfent sur le web chaque jour ?</h3>
                        {/* <h3>Alors contactez-nous!</h3> */}
                    </ReactWOW>

                    <ReactWOW delay='.1s' animation='fadeInRight'>
                        <div className="banner-btn">
                            <Link to="/contact" className="default-btn">
                                <i className="flaticon-web"></i>
                                Contactez-nous ! <span></span>
                            </Link>
                        </div>
                    </ReactWOW>
                    <br/> <br/>
                </div>
            </div>
        </div>
    )
}

export default MainBanner;