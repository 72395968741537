import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceSeoContent from '../components/ServiceDetails/ServiceSeoContent'
import StartProject from '../components/Common/StartProject'
import GetACall from '../components/Contact/GetACall'
import ContactForm from '../components/SEOAgency/ContactForm'
import MainBanner from '../components/SEOAgency/MainBanner';
 
const Seo = () => {
    return (
        <Layout>
            <Navbar />
            {/* <PageBanner
                pageTitle="SEO & Marketing" 
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="SEO & Marketing" 
            /> */}
            <MainBanner />
            <ServiceSeoContent />
            <ContactForm />
            <Footer />
        </Layout>
    );
}

export default Seo